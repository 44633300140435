/* eslint-disable */
import { DateTime } from 'luxon';

/* Get the current instant */
const now = DateTime.now();

export const analytics = {
    visitors           : {
        series: {
            'this-year': [
                {
                    name: "AnalyticalWorkbench",
                    data: [
                    {
                        "x": "2021-01-30",
                        "y": 514.5527695
                    },
                    {
                        "x": "2021-01-23",
                        "y": 0.028498772
                    },
                    {
                        "x": "2021-03-06",
                        "y": 0.009039728
                    },
                    {
                        "x": "2021-01-01",
                        "y": 0
                    },
                    {
                        "x": "2021-03-27",
                        "y": 23320.41097
                    },
                    {
                        "x": "2021-02-27",
                        "y": 0
                    },
                    {
                        "x": "2021-02-24",
                        "y": 684.9180586
                    },
                    {
                        "x": "2021-02-03",
                        "y": 8522.53701
                    },
                    {
                        "x": "2021-02-15",
                        "y": 0.012184441
                    },
                    {
                        "x": "2021-02-14",
                        "y": 0.007150256
                    },
                    {
                        "x": "2021-02-18",
                        "y": 1896.30835
                    },
                    {
                        "x": "2021-01-31",
                        "y": 149.6564331
                    },
                    {
                        "x": "2021-03-10",
                        "y": 0.470594534
                    },
                    {
                        "x": "2021-01-13",
                        "y": 1827.30624
                    },
                    {
                        "x": "2021-01-07",
                        "y": 0.918751034
                    },
                    {
                        "x": "2021-03-17",
                        "y": 0.023651751
                    },
                    {
                        "x": "2021-02-23",
                        "y": 0
                    },
                    {
                        "x": "2021-03-12",
                        "y": 0.005878398
                    },
                    {
                        "x": "2021-03-13",
                        "y": 3032.53792
                    },
                    {
                        "x": "2021-01-28",
                        "y": 0.00000429775
                    },
                    {
                        "x": "2021-02-16",
                        "y": 0.0000949997
                    },
                    {
                        "x": "2021-03-05",
                        "y": 0.003832216
                    },
                    {
                        "x": "2021-02-05",
                        "y": 0
                    },
                    {
                        "x": "2021-01-29",
                        "y": 0.135708068
                    },
                    {
                        "x": "2021-03-20",
                        "y": 33.55496942
                    },
                    {
                        "x": "2021-01-15",
                        "y": 13907.97964
                    },
                    {
                        "x": "2021-03-26",
                        "y": 0.000478295
                    },
                    {
                        "x": "2021-01-12",
                        "y": 0
                    },
                    {
                        "x": "2021-01-19",
                        "y": 0.193091692
                    },
                    {
                        "x": "2021-01-11",
                        "y": 6226.185366
                    },
                    {
                        "x": "2021-01-18",
                        "y": 0.00520137
                    },
                    {
                        "x": "2021-02-25",
                        "y": 0.121488996
                    },
                    {
                        "x": "2021-01-05",
                        "y": 1128.756466
                    },
                    {
                        "x": "2021-02-17",
                        "y": 944.5567135
                    },
                    {
                        "x": "2021-02-06",
                        "y": 3487.613883
                    },
                    {
                        "x": "2021-03-21",
                        "y": 0.103010676
                    },
                    {
                        "x": "2021-03-03",
                        "y": 0.228638179
                    },
                    {
                        "x": "2021-02-10",
                        "y": 0
                    },
                    {
                        "x": "2021-02-11",
                        "y": 0.008066131
                    },
                    {
                        "x": "2021-01-17",
                        "y": 133.7068443
                    },
                    {
                        "x": "2021-01-10",
                        "y": 2.056333816
                    },
                    {
                        "x": "2021-03-16",
                        "y": 0.000602856
                    },
                    {
                        "x": "2021-01-26",
                        "y": 1.593434589
                    },
                    {
                        "x": "2021-01-20",
                        "y": 0.388234455
                    },
                    {
                        "x": "2021-01-02",
                        "y": 0
                    },
                    {
                        "x": "2021-02-21",
                        "y": 147805.345
                    },
                    {
                        "x": "2021-02-02",
                        "y": 2124.996329
                    },
                    {
                        "x": "2021-03-18",
                        "y": 0.091378597
                    },
                    {
                        "x": "2021-02-07",
                        "y": 6725.719924
                    },
                    {
                        "x": "2021-03-22",
                        "y": 38953.32921
                    },
                    {
                        "x": "2021-01-03",
                        "y": 48034.58276
                    },
                    {
                        "x": "2021-01-16",
                        "y": 0
                    },
                    {
                        "x": "2021-03-25",
                        "y": 0.00000737567
                    },
                    {
                        "x": "2021-03-24",
                        "y": 0.025941255
                    },
                    {
                        "x": "2021-03-15",
                        "y": 316.2518026
                    },
                    {
                        "x": "2021-02-22",
                        "y": 56255.71458
                    },
                    {
                        "x": "2021-02-26",
                        "y": 0.245334669
                    },
                    {
                        "x": "2021-01-04",
                        "y": 9061.175665
                    },
                    {
                        "x": "2021-03-09",
                        "y": 623.6290308
                    },
                    {
                        "x": "2021-02-01",
                        "y": 14.49952099
                    },
                    {
                        "x": "2021-03-07",
                        "y": 33.87129234
                    },
                    {
                        "x": "2021-03-14",
                        "y": 3641.35968
                    },
                    {
                        "x": "2021-01-08",
                        "y": 1812.746293
                    },
                    {
                        "x": "2021-01-27",
                        "y": 481.7096447
                    },
                    {
                        "x": "2021-02-13",
                        "y": 0.0000955584
                    },
                    {
                        "x": "2021-02-09",
                        "y": 1.685834877
                    },
                    {
                        "x": "2021-03-08",
                        "y": 0.064041789
                    },
                    {
                        "x": "2021-03-02",
                        "y": 0.007585723
                    },
                    {
                        "x": "2021-02-08",
                        "y": 933.4362584
                    },
                    {
                        "x": "2021-02-12",
                        "y": 7.787234469
                    },
                    {
                        "x": "2021-03-23",
                        "y": 0.017359734
                    },
                    {
                        "x": "2021-02-04",
                        "y": 9992.36655
                    },
                    {
                        "x": "2021-03-04",
                        "y": 0.021414854
                    },
                    {
                        "x": "2021-01-21",
                        "y": 33.9069253
                    },
                    {
                        "x": "2021-01-14",
                        "y": 25.90007276
                    },
                    {
                        "x": "2021-03-11",
                        "y": 1.228106356
                    },
                    {
                        "x": "2021-01-22",
                        "y": 0.017733379
                    },
                    {
                        "x": "2021-02-28",
                        "y": 0.256481185
                    },
                    {
                        "x": "2021-03-01",
                        "y": 0.136714913
                    },
                    {
                        "x": "2021-02-19",
                        "y": 28443.43725
                    },
                    {
                        "x": "2021-03-19",
                        "y": 316.9226294
                    },
                    {
                        "x": "2021-02-20",
                        "y": 247636.6848
                    },
                    {
                        "x": "2021-01-25",
                        "y": 0.011637017
                    },
                    {
                        "x": "2021-01-09",
                        "y": 22.11317698
                    },
                    {
                        "x": "2021-01-06",
                        "y": 0.04055912
                    },
                    {
                        "x": "2021-01-24",
                        "y": 0.003126301
                    }
                    ]
                }
            ],
            'last-year': [
                {
                    name: 'Visitors',
                    data: [
                        {
                            x: now.minus({months: 24}).plus({day: 1}).toJSDate(),
                            y: 2021
                        },
                        {
                            x: now.minus({months: 24}).plus({day: 4}).toJSDate(),
                            y: 1749
                        },
                        {
                            x: now.minus({months: 24}).plus({day: 7}).toJSDate(),
                            y: 1654
                        },
                        {
                            x: now.minus({months: 24}).plus({day: 10}).toJSDate(),
                            y: 1900
                        },
                        {
                            x: now.minus({months: 24}).plus({day: 13}).toJSDate(),
                            y: 1647
                        },
                        {
                            x: now.minus({months: 24}).plus({day: 16}).toJSDate(),
                            y: 1315
                        },
                        {
                            x: now.minus({months: 24}).plus({day: 19}).toJSDate(),
                            y: 1807
                        },
                        {
                            x: now.minus({months: 24}).plus({day: 22}).toJSDate(),
                            y: 1793
                        },
                        {
                            x: now.minus({months: 24}).plus({day: 25}).toJSDate(),
                            y: 1892
                        },
                        {
                            x: now.minus({months: 24}).plus({day: 28}).toJSDate(),
                            y: 1846
                        },
                        {
                            x: now.minus({months: 23}).plus({day: 1}).toJSDate(),
                            y: 1804
                        },
                        {
                            x: now.minus({months: 23}).plus({day: 4}).toJSDate(),
                            y: 1778
                        },
                        {
                            x: now.minus({months: 23}).plus({day: 7}).toJSDate(),
                            y: 2015
                        },
                        {
                            x: now.minus({months: 23}).plus({day: 10}).toJSDate(),
                            y: 1892
                        },
                        {
                            x: now.minus({months: 23}).plus({day: 13}).toJSDate(),
                            y: 1708
                        },
                        {
                            x: now.minus({months: 23}).plus({day: 16}).toJSDate(),
                            y: 1711
                        },
                        {
                            x: now.minus({months: 23}).plus({day: 19}).toJSDate(),
                            y: 1570
                        },
                        {
                            x: now.minus({months: 23}).plus({day: 22}).toJSDate(),
                            y: 1507
                        },
                        {
                            x: now.minus({months: 23}).plus({day: 25}).toJSDate(),
                            y: 1451
                        },
                        {
                            x: now.minus({months: 23}).plus({day: 28}).toJSDate(),
                            y: 1522
                        },
                        {
                            x: now.minus({months: 22}).plus({day: 1}).toJSDate(),
                            y: 1977
                        },
                        {
                            x: now.minus({months: 22}).plus({day: 4}).toJSDate(),
                            y: 2367
                        },
                        {
                            x: now.minus({months: 22}).plus({day: 7}).toJSDate(),
                            y: 2798
                        },
                        {
                            x: now.minus({months: 22}).plus({day: 10}).toJSDate(),
                            y: 3080
                        },
                        {
                            x: now.minus({months: 22}).plus({day: 13}).toJSDate(),
                            y: 2856
                        },
                        {
                            x: now.minus({months: 22}).plus({day: 16}).toJSDate(),
                            y: 2745
                        },
                        {
                            x: now.minus({months: 22}).plus({day: 19}).toJSDate(),
                            y: 2750
                        },
                        {
                            x: now.minus({months: 22}).plus({day: 22}).toJSDate(),
                            y: 2728
                        },
                        {
                            x: now.minus({months: 22}).plus({day: 25}).toJSDate(),
                            y: 2436
                        },
                        {
                            x: now.minus({months: 22}).plus({day: 28}).toJSDate(),
                            y: 2289
                        },
                        {
                            x: now.minus({months: 21}).plus({day: 1}).toJSDate(),
                            y: 2804
                        },
                        {
                            x: now.minus({months: 21}).plus({day: 4}).toJSDate(),
                            y: 2777
                        },
                        {
                            x: now.minus({months: 21}).plus({day: 7}).toJSDate(),
                            y: 3024
                        },
                        {
                            x: now.minus({months: 21}).plus({day: 10}).toJSDate(),
                            y: 2657
                        },
                        {
                            x: now.minus({months: 21}).plus({day: 13}).toJSDate(),
                            y: 2218
                        },
                        {
                            x: now.minus({months: 21}).plus({day: 16}).toJSDate(),
                            y: 1964
                        },
                        {
                            x: now.minus({months: 21}).plus({day: 19}).toJSDate(),
                            y: 1674
                        },
                        {
                            x: now.minus({months: 21}).plus({day: 22}).toJSDate(),
                            y: 1721
                        },
                        {
                            x: now.minus({months: 21}).plus({day: 25}).toJSDate(),
                            y: 2005
                        },
                        {
                            x: now.minus({months: 21}).plus({day: 28}).toJSDate(),
                            y: 1613
                        },
                        {
                            x: now.minus({months: 20}).plus({day: 1}).toJSDate(),
                            y: 1071
                        },
                        {
                            x: now.minus({months: 20}).plus({day: 4}).toJSDate(),
                            y: 1079
                        },
                        {
                            x: now.minus({months: 20}).plus({day: 7}).toJSDate(),
                            y: 1133
                        },
                        {
                            x: now.minus({months: 20}).plus({day: 10}).toJSDate(),
                            y: 1536
                        },
                        {
                            x: now.minus({months: 20}).plus({day: 13}).toJSDate(),
                            y: 2016
                        },
                        {
                            x: now.minus({months: 20}).plus({day: 16}).toJSDate(),
                            y: 2256
                        },
                        {
                            x: now.minus({months: 20}).plus({day: 19}).toJSDate(),
                            y: 1934
                        },
                        {
                            x: now.minus({months: 20}).plus({day: 22}).toJSDate(),
                            y: 1832
                        },
                        {
                            x: now.minus({months: 20}).plus({day: 25}).toJSDate(),
                            y: 2075
                        },
                        {
                            x: now.minus({months: 20}).plus({day: 28}).toJSDate(),
                            y: 1709
                        },
                        {
                            x: now.minus({months: 19}).plus({day: 1}).toJSDate(),
                            y: 1831
                        },
                        {
                            x: now.minus({months: 19}).plus({day: 4}).toJSDate(),
                            y: 1434
                        },
                        {
                            x: now.minus({months: 19}).plus({day: 7}).toJSDate(),
                            y: 1293
                        },
                        {
                            x: now.minus({months: 19}).plus({day: 10}).toJSDate(),
                            y: 1064
                        },
                        {
                            x: now.minus({months: 19}).plus({day: 13}).toJSDate(),
                            y: 1080
                        },
                        {
                            x: now.minus({months: 19}).plus({day: 16}).toJSDate(),
                            y: 1032
                        },
                        {
                            x: now.minus({months: 19}).plus({day: 19}).toJSDate(),
                            y: 1280
                        },
                        {
                            x: now.minus({months: 19}).plus({day: 22}).toJSDate(),
                            y: 1344
                        },
                        {
                            x: now.minus({months: 19}).plus({day: 25}).toJSDate(),
                            y: 1835
                        },
                        {
                            x: now.minus({months: 19}).plus({day: 28}).toJSDate(),
                            y: 2287
                        },
                        {
                            x: now.minus({months: 18}).plus({day: 1}).toJSDate(),
                            y: 2692
                        },
                        {
                            x: now.minus({months: 18}).plus({day: 4}).toJSDate(),
                            y: 2250
                        },
                        {
                            x: now.minus({months: 18}).plus({day: 7}).toJSDate(),
                            y: 1814
                        },
                        {
                            x: now.minus({months: 18}).plus({day: 10}).toJSDate(),
                            y: 1906
                        },
                        {
                            x: now.minus({months: 18}).plus({day: 13}).toJSDate(),
                            y: 1973
                        },
                        {
                            x: now.minus({months: 18}).plus({day: 16}).toJSDate(),
                            y: 1882
                        },
                        {
                            x: now.minus({months: 18}).plus({day: 19}).toJSDate(),
                            y: 2333
                        },
                        {
                            x: now.minus({months: 18}).plus({day: 22}).toJSDate(),
                            y: 2048
                        },
                        {
                            x: now.minus({months: 18}).plus({day: 25}).toJSDate(),
                            y: 2547
                        },
                        {
                            x: now.minus({months: 18}).plus({day: 28}).toJSDate(),
                            y: 2884
                        },
                        {
                            x: now.minus({months: 17}).plus({day: 1}).toJSDate(),
                            y: 2771
                        },
                        {
                            x: now.minus({months: 17}).plus({day: 4}).toJSDate(),
                            y: 2522
                        },
                        {
                            x: now.minus({months: 17}).plus({day: 7}).toJSDate(),
                            y: 2543
                        },
                        {
                            x: now.minus({months: 17}).plus({day: 10}).toJSDate(),
                            y: 2413
                        },
                        {
                            x: now.minus({months: 17}).plus({day: 13}).toJSDate(),
                            y: 2002
                        },
                        {
                            x: now.minus({months: 17}).plus({day: 16}).toJSDate(),
                            y: 1838
                        },
                        {
                            x: now.minus({months: 17}).plus({day: 19}).toJSDate(),
                            y: 1830
                        },
                        {
                            x: now.minus({months: 17}).plus({day: 22}).toJSDate(),
                            y: 1872
                        },
                        {
                            x: now.minus({months: 17}).plus({day: 25}).toJSDate(),
                            y: 2246
                        },
                        {
                            x: now.minus({months: 17}).plus({day: 28}).toJSDate(),
                            y: 2171
                        },
                        {
                            x: now.minus({months: 16}).plus({day: 1}).toJSDate(),
                            y: 2988
                        },
                        {
                            x: now.minus({months: 16}).plus({day: 4}).toJSDate(),
                            y: 2694
                        },
                        {
                            x: now.minus({months: 16}).plus({day: 7}).toJSDate(),
                            y: 2806
                        },
                        {
                            x: now.minus({months: 16}).plus({day: 10}).toJSDate(),
                            y: 3040
                        },
                        {
                            x: now.minus({months: 16}).plus({day: 13}).toJSDate(),
                            y: 2898
                        },
                        {
                            x: now.minus({months: 16}).plus({day: 16}).toJSDate(),
                            y: 3013
                        },
                        {
                            x: now.minus({months: 16}).plus({day: 19}).toJSDate(),
                            y: 2760
                        },
                        {
                            x: now.minus({months: 16}).plus({day: 22}).toJSDate(),
                            y: 3021
                        },
                        {
                            x: now.minus({months: 16}).plus({day: 25}).toJSDate(),
                            y: 2688
                        },
                        {
                            x: now.minus({months: 16}).plus({day: 28}).toJSDate(),
                            y: 2572
                        },
                        {
                            x: now.minus({months: 15}).plus({day: 1}).toJSDate(),
                            y: 2789
                        },
                        {
                            x: now.minus({months: 15}).plus({day: 4}).toJSDate(),
                            y: 3069
                        },
                        {
                            x: now.minus({months: 15}).plus({day: 7}).toJSDate(),
                            y: 3142
                        },
                        {
                            x: now.minus({months: 15}).plus({day: 10}).toJSDate(),
                            y: 3614
                        },
                        {
                            x: now.minus({months: 15}).plus({day: 13}).toJSDate(),
                            y: 3202
                        },
                        {
                            x: now.minus({months: 15}).plus({day: 16}).toJSDate(),
                            y: 2730
                        },
                        {
                            x: now.minus({months: 15}).plus({day: 19}).toJSDate(),
                            y: 2951
                        },
                        {
                            x: now.minus({months: 15}).plus({day: 22}).toJSDate(),
                            y: 3267
                        },
                        {
                            x: now.minus({months: 15}).plus({day: 25}).toJSDate(),
                            y: 2882
                        },
                        {
                            x: now.minus({months: 15}).plus({day: 28}).toJSDate(),
                            y: 2885
                        },
                        {
                            x: now.minus({months: 14}).plus({day: 1}).toJSDate(),
                            y: 2915
                        },
                        {
                            x: now.minus({months: 14}).plus({day: 4}).toJSDate(),
                            y: 2790
                        },
                        {
                            x: now.minus({months: 14}).plus({day: 7}).toJSDate(),
                            y: 3071
                        },
                        {
                            x: now.minus({months: 14}).plus({day: 10}).toJSDate(),
                            y: 2802
                        },
                        {
                            x: now.minus({months: 14}).plus({day: 13}).toJSDate(),
                            y: 2382
                        },
                        {
                            x: now.minus({months: 14}).plus({day: 16}).toJSDate(),
                            y: 1883
                        },
                        {
                            x: now.minus({months: 14}).plus({day: 19}).toJSDate(),
                            y: 1448
                        },
                        {
                            x: now.minus({months: 14}).plus({day: 22}).toJSDate(),
                            y: 1176
                        },
                        {
                            x: now.minus({months: 14}).plus({day: 25}).toJSDate(),
                            y: 1275
                        },
                        {
                            x: now.minus({months: 14}).plus({day: 28}).toJSDate(),
                            y: 1136
                        },
                        {
                            x: now.minus({months: 13}).plus({day: 1}).toJSDate(),
                            y: 1160
                        },
                        {
                            x: now.minus({months: 13}).plus({day: 4}).toJSDate(),
                            y: 1524
                        },
                        {
                            x: now.minus({months: 13}).plus({day: 7}).toJSDate(),
                            y: 1305
                        },
                        {
                            x: now.minus({months: 13}).plus({day: 10}).toJSDate(),
                            y: 1725
                        },
                        {
                            x: now.minus({months: 13}).plus({day: 13}).toJSDate(),
                            y: 1850
                        },
                        {
                            x: now.minus({months: 13}).plus({day: 16}).toJSDate(),
                            y: 2304
                        },
                        {
                            x: now.minus({months: 13}).plus({day: 19}).toJSDate(),
                            y: 2187
                        },
                        {
                            x: now.minus({months: 13}).plus({day: 22}).toJSDate(),
                            y: 2597
                        },
                        {
                            x: now.minus({months: 13}).plus({day: 25}).toJSDate(),
                            y: 2246
                        },
                        {
                            x: now.minus({months: 13}).plus({day: 28}).toJSDate(),
                            y: 1767
                        }
                    ]
                }
            ]
        }
    },
    conversions        : {
        amount: 4123,
        labels: [
            now.minus({days: 47}).toFormat('dd MMM') + ' - ' + now.minus({days: 40}).toFormat('dd MMM'),
            now.minus({days: 39}).toFormat('dd MMM') + ' - ' + now.minus({days: 32}).toFormat('dd MMM'),
            now.minus({days: 31}).toFormat('dd MMM') + ' - ' + now.minus({days: 24}).toFormat('dd MMM'),
            now.minus({days: 23}).toFormat('dd MMM') + ' - ' + now.minus({days: 16}).toFormat('dd MMM'),
            now.minus({days: 15}).toFormat('dd MMM') + ' - ' + now.minus({days: 8}).toFormat('dd MMM'),
            now.minus({days: 7}).toFormat('dd MMM') + ' - ' + now.toFormat('dd MMM')
        ],
        series: [
            {
                name: 'Conversions',
                data: [4412, 4345, 4541, 4677, 4322, 4123]
            }
        ]
    },
    impressions        : {
        amount: 46085,
        labels: [
            now.minus({days: 31}).toFormat('dd MMM') + ' - ' + now.minus({days: 24}).toFormat('dd MMM'),
            now.minus({days: 23}).toFormat('dd MMM') + ' - ' + now.minus({days: 16}).toFormat('dd MMM'),
            now.minus({days: 15}).toFormat('dd MMM') + ' - ' + now.minus({days: 8}).toFormat('dd MMM'),
            now.minus({days: 7}).toFormat('dd MMM') + ' - ' + now.toFormat('dd MMM')
        ],
        series: [
            {
                name: 'Impressions',
                data: [11577, 11441, 11544, 11523]
            }
        ]
    },
    visits             : {
        amount: 62083,
        labels: [
            now.minus({days: 31}).toFormat('dd MMM') + ' - ' + now.minus({days: 24}).toFormat('dd MMM'),
            now.minus({days: 23}).toFormat('dd MMM') + ' - ' + now.minus({days: 16}).toFormat('dd MMM'),
            now.minus({days: 15}).toFormat('dd MMM') + ' - ' + now.minus({days: 8}).toFormat('dd MMM'),
            now.minus({days: 7}).toFormat('dd MMM') + ' - ' + now.toFormat('dd MMM')
        ],
        series: [
            {
                name: 'Visits',
                data: [15521, 15519, 15522, 15521]
            }
        ]
    },
    visitorsVsPageViews: {
        overallScore  : 472,
        averageRatio  : 45,
        predictedRatio: 55,
        series        : [
            {
                name: 'Page Views',
                data: [
                    {
                        x: now.minus({days: 65}).toJSDate(),
                        y: 4769
                    },
                    {
                        x: now.minus({days: 64}).toJSDate(),
                        y: 4901
                    },
                    {
                        x: now.minus({days: 63}).toJSDate(),
                        y: 4640
                    },
                    {
                        x: now.minus({days: 62}).toJSDate(),
                        y: 5128
                    },
                    {
                        x: now.minus({days: 61}).toJSDate(),
                        y: 5015
                    },
                    {
                        x: now.minus({days: 60}).toJSDate(),
                        y: 5360
                    },
                    {
                        x: now.minus({days: 59}).toJSDate(),
                        y: 5608
                    },
                    {
                        x: now.minus({days: 58}).toJSDate(),
                        y: 5272
                    },
                    {
                        x: now.minus({days: 57}).toJSDate(),
                        y: 5660
                    },
                    {
                        x: now.minus({days: 56}).toJSDate(),
                        y: 6026
                    },
                    {
                        x: now.minus({days: 55}).toJSDate(),
                        y: 5836
                    },
                    {
                        x: now.minus({days: 54}).toJSDate(),
                        y: 5659
                    },
                    {
                        x: now.minus({days: 53}).toJSDate(),
                        y: 5575
                    },
                    {
                        x: now.minus({days: 52}).toJSDate(),
                        y: 5474
                    },
                    {
                        x: now.minus({days: 51}).toJSDate(),
                        y: 5427
                    },
                    {
                        x: now.minus({days: 50}).toJSDate(),
                        y: 5865
                    },
                    {
                        x: now.minus({days: 49}).toJSDate(),
                        y: 5700
                    },
                    {
                        x: now.minus({days: 48}).toJSDate(),
                        y: 6052
                    },
                    {
                        x: now.minus({days: 47}).toJSDate(),
                        y: 5760
                    },
                    {
                        x: now.minus({days: 46}).toJSDate(),
                        y: 5648
                    },
                    {
                        x: now.minus({days: 45}).toJSDate(),
                        y: 5510
                    },
                    {
                        x: now.minus({days: 44}).toJSDate(),
                        y: 5435
                    },
                    {
                        x: now.minus({days: 43}).toJSDate(),
                        y: 5239
                    },
                    {
                        x: now.minus({days: 42}).toJSDate(),
                        y: 5452
                    },
                    {
                        x: now.minus({days: 41}).toJSDate(),
                        y: 5416
                    },
                    {
                        x: now.minus({days: 40}).toJSDate(),
                        y: 5195
                    },
                    {
                        x: now.minus({days: 39}).toJSDate(),
                        y: 5119
                    },
                    {
                        x: now.minus({days: 38}).toJSDate(),
                        y: 4635
                    },
                    {
                        x: now.minus({days: 37}).toJSDate(),
                        y: 4833
                    },
                    {
                        x: now.minus({days: 36}).toJSDate(),
                        y: 4584
                    },
                    {
                        x: now.minus({days: 35}).toJSDate(),
                        y: 4822
                    },
                    {
                        x: now.minus({days: 34}).toJSDate(),
                        y: 4330
                    },
                    {
                        x: now.minus({days: 33}).toJSDate(),
                        y: 4582
                    },
                    {
                        x: now.minus({days: 32}).toJSDate(),
                        y: 4348
                    },
                    {
                        x: now.minus({days: 31}).toJSDate(),
                        y: 4132
                    },
                    {
                        x: now.minus({days: 30}).toJSDate(),
                        y: 4099
                    },
                    {
                        x: now.minus({days: 29}).toJSDate(),
                        y: 3849
                    },
                    {
                        x: now.minus({days: 28}).toJSDate(),
                        y: 4010
                    },
                    {
                        x: now.minus({days: 27}).toJSDate(),
                        y: 4486
                    },
                    {
                        x: now.minus({days: 26}).toJSDate(),
                        y: 4403
                    },
                    {
                        x: now.minus({days: 25}).toJSDate(),
                        y: 4141
                    },
                    {
                        x: now.minus({days: 24}).toJSDate(),
                        y: 3780
                    },
                    {
                        x: now.minus({days: 23}).toJSDate(),
                        y: 3929
                    },
                    {
                        x: now.minus({days: 22}).toJSDate(),
                        y: 3524
                    },
                    {
                        x: now.minus({days: 21}).toJSDate(),
                        y: 3212
                    },
                    {
                        x: now.minus({days: 20}).toJSDate(),
                        y: 3568
                    },
                    {
                        x: now.minus({days: 19}).toJSDate(),
                        y: 3800
                    },
                    {
                        x: now.minus({days: 18}).toJSDate(),
                        y: 3796
                    },
                    {
                        x: now.minus({days: 17}).toJSDate(),
                        y: 3870
                    },
                    {
                        x: now.minus({days: 16}).toJSDate(),
                        y: 3745
                    },
                    {
                        x: now.minus({days: 15}).toJSDate(),
                        y: 3751
                    },
                    {
                        x: now.minus({days: 14}).toJSDate(),
                        y: 3310
                    },
                    {
                        x: now.minus({days: 13}).toJSDate(),
                        y: 3509
                    },
                    {
                        x: now.minus({days: 12}).toJSDate(),
                        y: 3311
                    },
                    {
                        x: now.minus({days: 11}).toJSDate(),
                        y: 3187
                    },
                    {
                        x: now.minus({days: 10}).toJSDate(),
                        y: 2918
                    },
                    {
                        x: now.minus({days: 9}).toJSDate(),
                        y: 3191
                    },
                    {
                        x: now.minus({days: 8}).toJSDate(),
                        y: 3437
                    },
                    {
                        x: now.minus({days: 7}).toJSDate(),
                        y: 3291
                    },
                    {
                        x: now.minus({days: 6}).toJSDate(),
                        y: 3317
                    },
                    {
                        x: now.minus({days: 5}).toJSDate(),
                        y: 3716
                    },
                    {
                        x: now.minus({days: 4}).toJSDate(),
                        y: 3260
                    },
                    {
                        x: now.minus({days: 3}).toJSDate(),
                        y: 3694
                    },
                    {
                        x: now.minus({days: 2}).toJSDate(),
                        y: 3598
                    },
                    {
                        x: now.minus({days: 1}).toJSDate(),
                        y: 3812
                    }
                ]
            },
            {
                name: 'Visitors',
                data: [
                    {
                        x: now.minus({days: 65}).toJSDate(),
                        y: 1654
                    },
                    {
                        x: now.minus({days: 64}).toJSDate(),
                        y: 1900
                    },
                    {
                        x: now.minus({days: 63}).toJSDate(),
                        y: 1647
                    },
                    {
                        x: now.minus({days: 62}).toJSDate(),
                        y: 1315
                    },
                    {
                        x: now.minus({days: 61}).toJSDate(),
                        y: 1807
                    },
                    {
                        x: now.minus({days: 60}).toJSDate(),
                        y: 1793
                    },
                    {
                        x: now.minus({days: 59}).toJSDate(),
                        y: 1892
                    },
                    {
                        x: now.minus({days: 58}).toJSDate(),
                        y: 1846
                    },
                    {
                        x: now.minus({days: 57}).toJSDate(),
                        y: 1966
                    },
                    {
                        x: now.minus({days: 56}).toJSDate(),
                        y: 1804
                    },
                    {
                        x: now.minus({days: 55}).toJSDate(),
                        y: 1778
                    },
                    {
                        x: now.minus({days: 54}).toJSDate(),
                        y: 2015
                    },
                    {
                        x: now.minus({days: 53}).toJSDate(),
                        y: 1892
                    },
                    {
                        x: now.minus({days: 52}).toJSDate(),
                        y: 1708
                    },
                    {
                        x: now.minus({days: 51}).toJSDate(),
                        y: 1711
                    },
                    {
                        x: now.minus({days: 50}).toJSDate(),
                        y: 1570
                    },
                    {
                        x: now.minus({days: 49}).toJSDate(),
                        y: 1507
                    },
                    {
                        x: now.minus({days: 48}).toJSDate(),
                        y: 1451
                    },
                    {
                        x: now.minus({days: 47}).toJSDate(),
                        y: 1522
                    },
                    {
                        x: now.minus({days: 46}).toJSDate(),
                        y: 1801
                    },
                    {
                        x: now.minus({days: 45}).toJSDate(),
                        y: 1977
                    },
                    {
                        x: now.minus({days: 44}).toJSDate(),
                        y: 2367
                    },
                    {
                        x: now.minus({days: 43}).toJSDate(),
                        y: 2798
                    },
                    {
                        x: now.minus({days: 42}).toJSDate(),
                        y: 3080
                    },
                    {
                        x: now.minus({days: 41}).toJSDate(),
                        y: 2856
                    },
                    {
                        x: now.minus({days: 40}).toJSDate(),
                        y: 2745
                    },
                    {
                        x: now.minus({days: 39}).toJSDate(),
                        y: 2750
                    },
                    {
                        x: now.minus({days: 38}).toJSDate(),
                        y: 2728
                    },
                    {
                        x: now.minus({days: 37}).toJSDate(),
                        y: 2436
                    },
                    {
                        x: now.minus({days: 36}).toJSDate(),
                        y: 2289
                    },
                    {
                        x: now.minus({days: 35}).toJSDate(),
                        y: 2657
                    },
                    {
                        x: now.minus({days: 34}).toJSDate(),
                        y: 2804
                    },
                    {
                        x: now.minus({days: 33}).toJSDate(),
                        y: 2777
                    },
                    {
                        x: now.minus({days: 32}).toJSDate(),
                        y: 3024
                    },
                    {
                        x: now.minus({days: 31}).toJSDate(),
                        y: 2657
                    },
                    {
                        x: now.minus({days: 30}).toJSDate(),
                        y: 2218
                    },
                    {
                        x: now.minus({days: 29}).toJSDate(),
                        y: 1964
                    },
                    {
                        x: now.minus({days: 28}).toJSDate(),
                        y: 1674
                    },
                    {
                        x: now.minus({days: 27}).toJSDate(),
                        y: 1721
                    },
                    {
                        x: now.minus({days: 26}).toJSDate(),
                        y: 2005
                    },
                    {
                        x: now.minus({days: 25}).toJSDate(),
                        y: 1613
                    },
                    {
                        x: now.minus({days: 24}).toJSDate(),
                        y: 1295
                    },
                    {
                        x: now.minus({days: 23}).toJSDate(),
                        y: 1071
                    },
                    {
                        x: now.minus({days: 22}).toJSDate(),
                        y: 799
                    },
                    {
                        x: now.minus({days: 21}).toJSDate(),
                        y: 1133
                    },
                    {
                        x: now.minus({days: 20}).toJSDate(),
                        y: 1536
                    },
                    {
                        x: now.minus({days: 19}).toJSDate(),
                        y: 2016
                    },
                    {
                        x: now.minus({days: 18}).toJSDate(),
                        y: 2256
                    },
                    {
                        x: now.minus({days: 17}).toJSDate(),
                        y: 1934
                    },
                    {
                        x: now.minus({days: 16}).toJSDate(),
                        y: 1832
                    },
                    {
                        x: now.minus({days: 15}).toJSDate(),
                        y: 2075
                    },
                    {
                        x: now.minus({days: 14}).toJSDate(),
                        y: 1709
                    },
                    {
                        x: now.minus({days: 13}).toJSDate(),
                        y: 1932
                    },
                    {
                        x: now.minus({days: 12}).toJSDate(),
                        y: 1831
                    },
                    {
                        x: now.minus({days: 11}).toJSDate(),
                        y: 1434
                    },
                    {
                        x: now.minus({days: 10}).toJSDate(),
                        y: 993
                    },
                    {
                        x: now.minus({days: 9}).toJSDate(),
                        y: 1064
                    },
                    {
                        x: now.minus({days: 8}).toJSDate(),
                        y: 618
                    },
                    {
                        x: now.minus({days: 7}).toJSDate(),
                        y: 1032
                    },
                    {
                        x: now.minus({days: 6}).toJSDate(),
                        y: 1280
                    },
                    {
                        x: now.minus({days: 5}).toJSDate(),
                        y: 1344
                    },
                    {
                        x: now.minus({days: 4}).toJSDate(),
                        y: 1835
                    },
                    {
                        x: now.minus({days: 3}).toJSDate(),
                        y: 2287
                    },
                    {
                        x: now.minus({days: 2}).toJSDate(),
                        y: 2226
                    },
                    {
                        x: now.minus({days: 1}).toJSDate(),
                        y: 2692
                    }
                ]
            }
        ]
    },
    newVsReturning     : {
        uniqueVisitors: 46085,
        series        : [
            5196.696904730999,
            1674.5764417271998,
            831.3048172,
            21993.02925548,
            9539.83378884712,
            464.9411654283,
            311.5967786,
            557.1023963866],
        labels        : [
            "Membership-Service",
            "FSTechInsurance",
            "Offer",
            "Dataplatform",
            "Hub-Network",
            "UPIPayment",
            "TCSGemsService",
            "ConversationalAIprogram"
        ]
    },
    gender             : {
        uniqueVisitors: 46085,
        series        : [55, 45],
        labels        : [
            'Male',
            'Female'
        ]
    },
    age                : {
        uniqueVisitors: 46085,
        series        : [35, 65],
        labels        : [
            'Under 30',
            'Over 30'
        ]
    },
    language           : {
        uniqueVisitors: 46085,
        series        : [25, 75],
        labels        : [
            'English',
            'Other'
        ]
    },

    budgetDetails     : {
        columns: ['resourceType', 'resourceName', 'productName', 'totalCost'],
        rows   : [
            {
                "id": 1,
                "resourceType": "microsoft.operationalinsights",
                "resourceName": "law-tdses-pr-st-ci-01",
                "productName": "Azure Monitor - 1000 GB Commitment Tier - IN Central",
                "totalCost": 8986539.125
            },
            {
                "id": 2,
                "resourceType": "Microsoft.Databricks",
                "resourceName": "dbw-tdprd-pr-ci-dat-02",
                "productName": "Azure Databricks - Premium - Jobs Compute - DBU",
                "totalCost": 3639182.603
            },
            {
                "id": 3,
                "resourceType": "microsoft.operationalinsights",
                "resourceName": "defaultworkspace-544ae53b-ea88-43bd-9a81-85354a7d2b5b-cin",
                "productName": "Log Analytics - Data Ingestion - IN Central",
                "totalCost": 3421440.51
            },
            {
                "id": 4,
                "resourceType": "Microsoft.Capacity",
                "resourceName": null,
                "productName": "Virtual Machines Dv3/DSv3 Series - D16 v3/D16s v3 - IN Central",
                "totalCost": 3142119.75
            },
            {
                "id": 5,
                "resourceType": "Microsoft.Capacity",
                "resourceName": null,
                "productName": "Virtual Machines Dv3/DSv3 Series - D32 v3/D32s v3 - IN Central",
                "totalCost": 2982347
            },
            {
                "id": 6,
                "resourceType": "Microsoft.Capacity",
                "resourceName": null,
                "productName": "Virtual Machines Ddsv4 Series - D16ds v4 - IN Central",
                "totalCost": 1970365
            },
            {
                "id": 7,
                "resourceType": "microsoft.operationalinsights",
                "resourceName": "defaultworkspace-7c22b9ac-83df-4853-894e-17ce30d55c6f-cin",
                "productName": "Azure Monitor - 100 GB Commitment Tier - IN Central",
                "totalCost": 1692867.746
            },
            {
                "id": 8,
                "resourceType": "Microsoft.Storage",
                "resourceName": "statdprdprcidatgrs04",
                "productName": "Microsoft Defender for Storage - Standard Transactions",
                "totalCost": 1397975.03
            },
            {
                "id": 9,
                "resourceType": "Microsoft.Databricks",
                "resourceName": "dbw-tdprp-pp-ci-dat-01",
                "productName": "Azure Databricks - Standard - All-purpose Compute - DBU",
                "totalCost": 1376566.439
            },
            {
                "id": 10,
                "resourceType": "Microsoft.Capacity",
                "resourceName": null,
                "productName": "Virtual Machines Dv3/DSv3 Series - D4 v3/D4s v3 - IN Central",
                "totalCost": 1371340.375
            }
        ]
    },

    // {
    //     id                 : 1,
    //     type               : 'Dataplatform',
    //     total              : 14880,
    //     expensesAmount     : 14000,
    //     expensesPercentage : 94.08,
    //     remainingAmount    : 880,
    //     remainingPercentage: 5.92
    // },
    // {
    //     id                 : 2,
    //     type               : 'FSTechInsurance',
    //     total              : 21080,
    //     expensesAmount     : 17240.34,
    //     expensesPercentage : 81.78,
    //     remainingAmount    : 3839.66,
    //     remainingPercentage: 18.22
    // },
    // {
    //     id                 : 3,
    //     type               : 'Membership-Service',
    //     total              : 34720,
    //     expensesAmount     : 3518,
    //     expensesPercentage : 10.13,
    //     remainingAmount    : 31202,
    //     remainingPercentage: 89.87
    // },
    // {
    //     id                 : 4,
    //     type               : 'UPIPayment',
    //     total              : 18600,
    //     expensesAmount     : 0,
    //     expensesPercentage : 0,
    //     remainingAmount    : 18600,
    //     remainingPercentage: 100
    // },
    // {
    //     id                 : 5,
    //     type               : 'Marketing',
    //     total              : 34720,
    //     expensesAmount     : 19859.84,
    //     expensesPercentage : 57.2,
    //     remainingAmount    : 14860.16,
    //     remainingPercentage: 42.8
    // }

    costOverview: {
        "series": [
          {
            "name": "SharedApplications",
            "data": [
              1484241.0647236363,
              94208.62014862109,
              487778.74242548697,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "BillPayment",
            "data": [
              945501.5100418009,
              650687.4099139239,
              11493.42591161,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "PWA",
            "data": [
              101945.10729897901,
              321705.71117496915,
              3824.004517,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "Customer-360",
            "data": [
              411679.9177103769,
              23847.4516781546,
              49206.14031915538,
              426509.65732502297,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "DataPipeline",
            "data": [
              1383553.597925574,
              20178.731540565663,
              727884.0488783605,
              913623.1043137199,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "InfraAzure",
            "data": [
              18203089.215443198,
              2344146.5909744385,
              5767113.246015187,
              77976.54757123861,
              94414.02421198999,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "Kogito",
            "data": [
              271846.47482726735,
              33595.306987867,
              7382.01516038,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "MembershipService",
            "data": [
              27985.719921683998,
              3513.524878533,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "DataPlatform",
            "data": [
              3860642.5000857166,
              17971721.317691956,
              2240334.434156979,
              922564.1708717423,
              3187568.8199211597,
              0,
              0,
              0,
              6866864.0016592,
              0,
              0
            ]
          },
          {
            "name": "Resulticks",
            "data": [
              365146.190956426,
              151095.62237881898,
              107384.96222196001,
              771030.026718149,
              621309.26685852,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "AnalyticalWorkbench",
            "data": [
              83365.67061055076,
              515478.25819307216,
              70274.30578178968,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "Datascience",
            "data": [
              664376.7906120907,
              27987.999516377808,
              559381.3982282999,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "Untagged",
            "data": [
              1190018.063710786,
              2579897.3192070657,
              3162677.3086633487,
              845811.6750106129,
              10322766.339407539,
              248838.187427548,
              19887097.972557597,
              311531.199609,
              3813083.9174961676,
              27155352.58152343,
              409591.72120329685
            ]
          },
          {
            "name": "SharedManagement",
            "data": [
              16130.700858624696,
              8818.858077668201,
              20832.446670883,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "MADStackPlatform",
            "data": [
              1781242.2082567273,
              4152559.706425886,
              970287.2398463062,
              474249.18877881,
              1412791.84005101,
              272169.21732500003,
              0,
              0,
              3115007.0261622095,
              0,
              0
            ]
          },
          {
            "name": "Offers",
            "data": [
              2647144.610430757,
              547095.287262473,
              5232.847311414999,
              978144.332927798,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "POC",
            "data": [
              2200.9947739396703,
              16808.4055430845,
              5999.92574133577,
              17690.821103397604,
              83.69751127020001,
              0,
              0,
              0,
              4715.515682671,
              0,
              0
            ]
          },
          {
            "name": "Payment",
            "data": [
              1042083.438415748,
              1511589.0781008853,
              941802.9230275832,
              21178.097415999997,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "MSD",
            "data": [
              1195443.0332501363,
              1966344.9278006654,
              13677.5945769,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "FIS",
            "data": [
              485229.91610025504,
              162955.89765232502,
              10657.377394136998,
              337017.990222225,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "ConversationalAIProgram",
            "data": [
              617282.9447686672,
              12352.0009787296,
              70147.125950715,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "Universal_Cart",
            "data": [
              651416.593879235,
              64470.094579643606,
              139387.25797023298,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            "name": "Channels",
            "data": [
              36052.693579225,
              167363.05767901454,
              25450.153146669,
              3640349.2300349623,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          }
        ],
        "xaxis": {
          "categories": [
            "Jan-2021",
            "Feb-2021",
            "Mar-2021",
            "Apr-2021",
            "May-2021",
            "Jul-2021",
            "Oct-2021",
            "Nov-2021",
            "Jun-2021",
            "Sep-2021",
            "Aug-2021"
          ]
        }
    },

    azureMonitor: {
        "series": [
          {
            "name": "TDL-Preprod",
            "data": [111, 211, 612, 740, 5, 76, 27, 4284, 5, 909, 11]
          },
        //   {
        //     "name": "TDL-Production1",
        //     "data": [111, 211, 612, 740, 5, 76, 27, 4284, 5, 909, 11]
        //   },
        //   {
        //     "name": "TDL-Prod",
        //     "data": [111, 211, 612, 740, 5, 76, 27, 4284, 5, 909, 11]
        //   },
        //   {
        //     "name": "TDL-Preprod",
        //     "data": [111, 211, 612, 740, 5, 76, 27, 4284, 5, 909, 11]
        //   },
        //   {
        //     "name": "TDL-Production1",
        //     "data": [111, 211, 612, 740, 5, 76, 27, 4284, 5, 909, 11]
        //   },
        //   {
        //     "name": "TDL-Prod",
        //     "data": [111, 211, 612, 740, 5, 76, 27, 4284, 5, 909, 11]
        //   },
        //   {
        //     "name": "TDL-Prod",
        //     "data": [111, 211, 612, 740, 5, 76, 27, 4284, 5, 909, 11]
        //   },
        //   {
        //     "name": "TDL-Sharedservices",
        //     "data": [111, 211, 612, 740, 5, 76, 27, 4284, 5, 909, 11]
        //   },
        //   {
        //     "name": "TDL-Performance",
        //     "data": [111, 211, 612, 740, 5, 76, 27, 4284, 5, 909, 11]
        //   },
        //   {
        //     "name": "TDL-SIT",
        //     "data": [111, 211, 612, 740, 5, 76, 27, 4284, 5, 909, 11]
        //   },
        //   {
        //     "name": "TDL-SIT",
        //     "data": [111, 211, 612, 740, 5, 76, 27, 4284, 5, 909, 11]
        //   }
        ],
        "xaxis": {
          "categories": [
            "Offers",
            "Universal_Cart",
            "Untagged",
            "MADStackPlatform",
            "Integration",
            "InfraAzure",
            "DataPlatform",
            "BillPayment",
            "StreamAnalytics",
            "Payment"
          ]
        }
    },
};
